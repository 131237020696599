
//import React, { useState } from 'react';

import { loginRequest } from './authConfig';
import { Routes, Route } from "react-router-dom";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import axios from "axios";

import './App.css';

import Topbar from "./scenes/global/topbar";
import Sidebar from "./scenes/global/sidebar";
import Home from "./scenes/home";
import Calculate from "./scenes/calculate";
import Answers from "./scenes/answers";
//import Questions from "./scenes/questions";
import EditQuestions from "./scenes/editQuestions";

//let userData = {};
let apiHost = process.env.REACT_APP_API_HOST;
if (apiHost == null) {
    apiHost = 'http://localhost:8000';
}

const UnauthorizedContent = () => {

    if (axios.defaults.headers.common['Authorization'] !== "") {
        axios.post(apiHost + "/logout/",
            {
                withCredentials: true,
            }
        )
        .catch((res) => {
            console.log('Error: ', res.response.statusText);
        });

        // Reset headers
        axios.defaults.headers.common['Authorization'] = "";
        localStorage.setItem("token", "");
    }

    return (<></>);
};


const ProfileContent = () => {
    const { instance, accounts } = useMsal();

    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
    })
    .then((response) => {
        //userData = {};
        axios.post(apiHost + "/microsoft/",
            {
                withCredentials: true,
                access_token: response.accessToken
            }
        )
        .then((res) => {
            axios.defaults.headers.common['Authorization'] = `Token ${res.data.key}`;
            localStorage.setItem("token", `Token ${res.data.key}`);
        })
        .catch((res) => {
            console.log('Error: ', res.response.statusText);
        })
    });

    return (<></>);
};

/**
* If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
*/
const MainContent = () => {
    return (
        <div>
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <UnauthorizedContent />
            </UnauthenticatedTemplate>
        </div>
    );
};

function App() {
    return (
        <div className="app">
            <MainContent />
            <Sidebar />
            <main className="content">
                <Topbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/calculate" element={<Calculate />} />
                    <Route path="/answers" element={<Answers />} />
                    <Route path="/edit_questions" element={<EditQuestions />} />
                </Routes>
            </main>
        </div>
    );
}

export default App;