

import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */

export const SignInButton = () => {
    const { instance } = useMsal();
  
    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => { console.log(e); });
    };
    
  
    return (
        <Button
            variant="secondary"
            className="ml-auto"
            title="Sign In"
            onClick={() => handleLogin()}
        >Sign In
        </Button>
    );
};

export const SignOutButton = () => {
    const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
        onRedirectNavigate: (url) => {
            // Return false if you would like to stop navigation after local logout
            return true;
        }
    });
  };

  return (
    <Button
      variant="secondary"
      className="ml-auto"
      title="Sign Out"
      onClick={() => handleLogout()}
    >Sign Out
    </Button>
  );
};

const TopbarObject = () => {
    const isAuthenticated = useIsAuthenticated();
  
    return (
      <>
        <Navbar bg="primary" variant="dark" className="navbarStyle">
          <a className="navbar-brand" href="/">
            Microsoft Identity Platform
          </a>
          <div className="collapse navbar-collapse justify-content-end">
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </div>
        </Navbar>
      </>
    );
  };

  export default TopbarObject;