
import { useEffect, useState } from 'react';
import axios from "axios";

import Header from "../../components/Header";


const Calculate = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let apiHost = process.env.REACT_APP_API_HOST;
    if (apiHost == null) {
        apiHost = 'http://localhost:8000';
    }

    if (error || loading){

    }

    const fetchCalculate = async () => {
        setLoading(true);
        setData([]);
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
        await axios
        .get(apiHost + '/calculate', {
            withCredentials: true,
        })
        .then(
            (res) => {
                setData(res.data);
                setLoading(false);
            }
        )
        .catch(
            (res) => {
                setError(true);
                console.log('Error: ', res.response.statusText);
            }
        )
    };

    // eslint-disable-next-line
    useEffect(() => { fetchCalculate(); }, []);

    const renderObject = (obj, path = '') => {
        let elements = [];
        for (const func in obj) {
            if (func === 'average') {
                continue;
            }

            const average = obj[func]['average'];
            const category_count = Object.keys(obj[func]).length - 1; // Minus one because every part has an average key
            
            let index = 0;
            for (const category in obj[func]) {
                if (category === 'average') {
                    continue;
                }
                const category_avg = obj[func][category]['average'];

                if (index === 0) {
                    elements.push(
                        <tr key={"data" + func + index}>
                            <td rowSpan={category_count} style={{padding: 10 + "px", border: 1 + "px solid black"}}>{func}</td>
                            <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>{category}</td>
                            <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>{category_avg}</td>
                            <td rowSpan={category_count} style={{padding: 10 + "px", border: 1 + "px solid black"}}>{average}</td>
                        </tr>
                    );
                }
                else {
                    elements.push(
                        <tr key={"category" + func + index}>
                            <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>{category}</td>
                            <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>{category_avg}</td>
                        </tr>
                    );
                }
                index += 1;
            }
        };

        return <table><tbody>{elements}</tbody></table>;
    };

    const getSubDictionary = (obj, path) => {
        const keys = path.split('.').filter(Boolean);
        let result = obj;
        for (const key of keys) {
            if (result[key] !== undefined) {
                result = result[key];
            } else {
                return null;
            }
        }
        return result;
      };

    return (
    <div m="20px">
        <div display="flex" justifyContent="space-between" alignItems="center"></div>
        <Header title="CALCULATE" subtitle="Calculate" />
        <br />
        <div>
            Raw Score: {getSubDictionary(data, 'raw_score.average') || 0.0}
        </div>
        {getSubDictionary(data, 'raw_score') !== null
        ?    <div>
                {renderObject(getSubDictionary(data, 'raw_score'))}
            </div>
        : <></>
        }

        <div style={{marginTop: 2 + "em"}}>
            Weighted Score: {getSubDictionary(data, 'weighted_score.average') || 0.0}
        </div>
        {getSubDictionary(data, 'weighted_score') !== null
        ?    <div>
                {renderObject(getSubDictionary(data, 'weighted_score'))}
            </div>
        : <></>
        }
    </div>);
};

export default Calculate;