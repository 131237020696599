

import Header from "../../components/Header";

const Home = () => {

    return (<div m="20px">
        <div display="flex" justifyContent="space-between" alignItems="center"></div>
        <Header title="HOME" subtitle="Welcome" />
    </div>);
}

export default Home;