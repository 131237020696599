
import { useEffect, useState } from 'react';
import axios from "axios";

import Header from "../../components/Header";



const divList = (list, options, multiselect, className) => {
    let elements = [<option key={"default0"} defaultValue=""></option>];
    let names = [];
    for (const index in list) {
        elements.push(
            <option key={"selected" + index} defaultValue={list[index]}>{list[index]}</option>
        );
        names.push(list[index]);
    }

    for (const index in options) {
        if (!(names.includes(options[index].option))) {
            elements.push(
                <option key={"options" + index} defaultValue={options[index].name}>{options[index].option}</option>
            );
        }
    }
    if (multiselect) {
        return <select defaultValue={names} className={className} multiple={multiselect}>{elements}</select>
    }
    else {
        return <select defaultValue={list[0]} className={className}>{elements}</select>
    }
}


const Answers = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saved, setSaved] = useState(false);
    
    let apiHost = process.env.REACT_APP_API_HOST;
    if (apiHost == null) {
        apiHost = 'http://localhost:8000';
    }

    if (error || loading){
    }

    const fetchAnswers = async () => {
        setLoading(true);
        setData([]);
        
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
        await axios
        .get(apiHost + '/answers', {
            withCredentials: true,
            //params: {
            //    'basic': false,
            //}
        })
        .then(
            (res) => {
                setData(res.data);
                setLoading(false);
            }
        )
        .catch(
            (res) => {
                setError(true);
                console.log('Error: ', res.response.statusText);
            }
        )
    };

    const saveAnswers = async () => {

        let answersArray = [];
        const answersRows = document.getElementById('answersTable').getElementsByClassName('answerRow');
        for (const index in answersRows) {
            if (index !== 'length' && index !== 'item' && index !== 'namedItem') {

                answersArray.push({
                    'question_id': answersRows[index].getElementsByClassName('questionID')[0].value,
                    'answer_id': answersRows[index].getElementsByClassName('answerID')[0].value,
                    'question': answersRows[index].getElementsByClassName('question')[0].value,
                    'answer': answersRows[index].getElementsByClassName('answerOptions')[0].value,
                });
            }
        }


        setLoading(true);
        setSaved(false);
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
        await axios
        .post(apiHost + '/save_answers', {
            withCredentials: true,
            params: {
                'answers': answersArray,
            }
        })
        .then(
            (res) => {
                setLoading(false);
                setSaved(true);
            }
        )
        .catch(
            (res) => {
                setError(true);
                console.log('Error: ', res.response.statusText);
            }
        )
    };

    // eslint-disable-next-line
    useEffect(() => { fetchAnswers();}, []);

    const renderObject = (obj) => {
        let elements = [];
        const option_obj = obj.options;
        const answers_obj = obj.answers;
        for (const index in answers_obj) {
            elements.push(
                <tbody key={"answer" + index}>
                    <tr className="answerRow">
                        <td style={{padding: 10 + "px", border: 1 + "px solid black", display: "none"}}>
                            <input className="questionID" type="text" disabled readOnly value={answers_obj[index].question_id} />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black", display: "none"}}>
                            <input className="answerID" type="text" disabled readOnly value={answers_obj[index].answer_id} />
                        </td>
                        <td className="question" style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            <textarea disabled className="question" defaultValue={answers_obj[index].question} />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black", width: 10 + "em"}}>
                            {divList([answers_obj[index].answer], option_obj, false, "answerOptions")}
                        </td>
                    </tr>
                </tbody>
            );
        };

        const questions_obj = obj.questions;
        for (const index in questions_obj) {
            elements.push(
                <tbody key={"question" + index}>
                    <tr className="answerRow">
                        <td style={{padding: 10 + "px", border: 1 + "px solid black", display: "none"}}>
                            <input className="questionID" type="text" disabled readOnly value={questions_obj[index].id} />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black", display: "none"}}>
                            <input className="answerID" type="text" disabled readOnly value="" />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            <textarea style={{width: 100 + "%"}} disabled className="question" defaultValue={questions_obj[index].question} />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black", width: 10 + "em"}}>
                            {divList([], option_obj, false, "answerOptions")}
                        </td>
                    </tr>
                </tbody>
            );
        }

        return <table id="answersTable" style={{width: 100 + "%"}}>{elements}</table>;
    };

    return (
    <div m="20px">
        <div display="flex" justifyContent="space-between" alignItems="center"></div>
        <Header title="ANSWERS" subtitle="Answers" />
        <br />

        <button onClick={saveAnswers}>Save</button>
        <div>
            { saved
            ? <div style={{background: "green", color: "white", width: 10 + "em"}}>Saved</div>
            : <div ></div>
            }
        </div>

        {renderObject(data)}
    </div>);
};

export default Answers;