
import { useEffect, useState } from 'react';
import axios from "axios";

import Header from "../../components/Header";



const divList = (list, options, multiselect, className) => {
    let elements = [];
    let names = [];
    for (const index in list) {
        elements.push(
            <option key={"selected" + index} defaultValue={list[index]}>{list[index]}</option>
        );
        names.push(list[index]);
    }

    for (const index in options) {
        if (!(names.includes(options[index].name))) {
            elements.push(
                <option key={"options" + index} defaultValue={options[index].name}>{options[index].name}</option>
            );
        }
    }
    if (multiselect) {
        return <select defaultValue={names} className={className} multiple={multiselect}>{elements}</select>
    }
    else {
        return <select defaultValue={names[0]} className={className}>{elements}</select>
    }
}


const  getSelectValues = (select) => {
    var result = [];
    var options = select && select.options;
    var opt;
  
    for (var i=0, iLen=options.length; i<iLen; i++) {
      opt = options[i];
  
      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result;
  }


const EditQuestions = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [checkbox, setCheckbox] = useState(true);
    const [saved, setSaved] = useState(false);
    const handleChange = (e) => setCheckbox(e.target.checked);

    let apiHost = process.env.REACT_APP_API_HOST;
    if (apiHost == null) {
        apiHost = 'http://localhost:8000';
    }
    
    if (checkbox || error || loading){
    }

    const fetchQuestions = async () => {
        setLoading(true);
        setData([]);
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
        await axios
        .get(apiHost + '/questions', {
            withCredentials: true,
        })
        .then(
            (res) => {
                setData(res.data);
                setLoading(false);
            }
        )
        .catch(
            (res) => {
                setError(true);
                console.log('Error: ', res.response.statusText);
            }
        )
    };

    const saveQuestions = async () => {

        let questionsArray = [];
        const questionsRows = document.getElementById('questionsTable').getElementsByClassName('questionRow');
        for (const index in questionsRows) {
            if (index !== 'length' && index !== 'item' && index !== 'namedItem') {

                let subControls = [];
                const subControlHTML = questionsRows[index].getElementsByClassName('questionSubControl');
                for (let i = 0; i < subControlHTML.length; i++) {
                    subControls = getSelectValues(subControlHTML[i]);
                }

                let assetControls = [];
                const assetControlHTML = questionsRows[index].getElementsByClassName('questionAssetClass');
                for (let i = 0; i < assetControlHTML.length; i++) {
                    assetControls = getSelectValues(assetControlHTML[i]);
                }

                questionsArray.push({
                    'id': questionsRows[index].getElementsByClassName('questionID')[0].value,
                    'question': questionsRows[index].getElementsByClassName('question')[0].value,
                    'function': questionsRows[index].getElementsByClassName('questionFunction')[0].value,
                    'category': questionsRows[index].getElementsByClassName('questionCategory')[0].value,
                    'control': questionsRows[index].getElementsByClassName('questionControl')[0].value,
                    'sub_control': subControls,
                    'asset_class': assetControls,
                    'remediation': questionsRows[index].getElementsByClassName('questionRemediation')[0].value,
                    'is_basic': questionsRows[index].getElementsByClassName('questionIsBasic')[0].checked,
                });
            }
        }


        setLoading(true);
        setSaved(false);
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
        await axios
        .post(apiHost + '/save_questions', {
            withCredentials: true,
            params: {
                'questions': questionsArray,
            }
        })
        .then(
            (res) => {
                setLoading(false);
                setSaved(true);
            }
        )
        .catch(
            (res) => {
                setError(true);
                console.log('Error: ', res.response.statusText);
            }
        )
    };

    // eslint-disable-next-line
    useEffect(() => { fetchQuestions(); }, []);

    const renderObject = (obj) => {
        let elements = [];
        const questions_obj = obj.questions;
        elements.push(
            <tbody key={"header0"}>
                <tr>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black", display: "none"}}>ID</td>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>Question</td>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>Function</td>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>Category</td>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>Control</td>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>SubControl</td>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>AssetClass</td>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>Remediation</td>
                    <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>Basic</td>
                </tr>
            </tbody>
        );

        for (const index in questions_obj) {
            elements.push(
                <tbody key={"row" + index}>
                    <tr className="questionRow">
                        <td style={{padding: 10 + "px", border: 1 + "px solid black", display: "none"}}>
                            <input className="questionID" type="text" disabled readOnly value={questions_obj[index].id} />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            <textarea className="question" defaultValue={questions_obj[index].question} />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            {divList([questions_obj[index].function], obj.functions, false, "questionFunction")}
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            {divList([questions_obj[index].category], obj.categories, false, "questionCategory")}
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            <textarea className="questionControl" type="text" defaultValue={questions_obj[index].control} />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            {divList(questions_obj[index].sub_control, obj.sub_controls, true, "questionSubControl")}
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            {divList(questions_obj[index].asset_class, obj.asset_classes, true, "questionAssetClass")}
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            <textarea className="questionRemediation" defaultValue={questions_obj[index].remediation} />
                        </td>
                        <td style={{padding: 10 + "px", border: 1 + "px solid black"}}>
                            <input className="questionIsBasic" type="checkbox" defaultChecked={questions_obj[index].is_basic} onChange={handleChange} />
                        </td>
                    </tr>
                </tbody>
            );
        };

        return <table style={{width: 100 + "%"}} id="questionsTable">
            {elements}
        </table>;
    };

    return (
    <div m="20px">
        <div display="flex" justifyContent="space-between" alignItems="center"></div>
        <Header title="EDIT QUESTIONS" subtitle="Edit Questions" />
        <br />
        <button onClick={saveQuestions}>Save</button>

        <div>
            { saved
            ? <div style={{background: "green", color: "white", width: 10 + "em"}}>Saved</div>
            : <div ></div>
            }
        </div>

        {renderObject(data)}
    </div>);
};

export default EditQuestions;